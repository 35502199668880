body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body,#root{
  height: 100%,
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.primaryColor {
  color: #30539d;
}

.navbar-bg-primary {
  background-color: #30539d !important;
}

.btn-primary {
  background-color: #30539d !important;
}

.btn-outline-primary {
  color: #30539d !important;
  border-color: #30539d !important;
  &:active {
    color: #fff !important;
    background-color: #30539d !important;
  }
  &:hover {
    color: #fff !important;
    background-color: #30539d !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.page-item.active > .page-link {
  background-color: #30539d !important;
}

.width-100 {
  width: 100%;
}
.text-center{
  text-align: center;
}
